.photo-carousel-container {
  padding: 20px;
  width: auto;
  @media screen and (max-width:480px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.slick-track {
  // height: 175px;
  display: flex;
  align-items: center;
}

.photo-slider-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  
}

.photo-slider-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Використовуйте object-fit: contain, якщо хочете, щоб зображення вміщувалися повністю, не обрізаючись */
}
.photo-slider {
  display: flex;
  justify-content: center; /* відцентровує слайдер по горизонталі */
}

.custom-button {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;
  background-color: rgba(0, 255, 255, 0);
}
.photo-slider-slide {
  padding: 0 5px; /* додає відступи між слайдами */
  box-sizing: border-box; /* щоб padding не впливав на розміри слайду */
}

.custom-button-next,
.custom-button-prev {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button-next {
  right: 10px;
}

.custom-button-prev {
  left: 10px;
}

.custom-button-next:hover {
  background-color: white;
}

.custom-button-prev:hover {
  background-color: white;
}
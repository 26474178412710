.carousel-status {
  display: none !important;
}

.control-prev {
  top: 50% !important;
  left: 5% !important;
  background-image: url(../img/Vlevowhite.png) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  color: white !important;
  background-position: center !important;
  background-color: rgb(50, 45, 40, 0.6) !important;
}

.control-next {
  top: 50% !important;
  right: 5% !important;
  background-image: url(../img/vpravowhite.png) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  color: white !important;
  background-color: white !important;
  background-position: center !important;
  background-color: rgb(50, 45, 40, 0.6) !important;
}

.control-next:hover {
  background-image: url(../img/vpravowhite.png) !important;
}

.control-prev:hover {
  background-image: url(../img/Vlevowhite.png) !important;
}

.control-arrow::before {
  content: none !important;
}
.control-arrow {
  opacity: 1 !important;
}

.control-arrow:hover {
  background-color: rgb(50, 45, 40, 0.8) !important;
}

.carousel img{
  width: max-content !important;
}

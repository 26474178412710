.penalBox{
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
}
.titlePenal{
    font-family: Montserrat, sans-serif;
    font-size: 36px;
    line-height: 46px;
    font-weight: 700;
    position: relative;
    letter-spacing: 3.6px;
    text-align: center;
    text-transform: uppercase;
}
.textPenal{
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    // line-height: 46px;
    font-weight:300;
    position: relative;
    letter-spacing: 1.6px;
    // text-align: center;
    // text-transform: uppercase;
}

.contacts {
  background-color: gray;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  text-align: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.contacts___title {
  margin-bottom: 24px;
}

.map {
  padding: 0;
  margin-bottom: -12px;
  flex: 1;
  height: 100%;
  border-radius: 8px;
}

.number{
  text-decoration: none;
  color: white;
}


.contacts__label {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.contacts__phone,
.contacts__phone_2,
.contacts__mail {
  margin: 5px 0;
  color: black;
  font-weight: 500;
  text-decoration: none;
  display: block;
}

.contacts__address {
  color: black;
  font-weight: 500;
  text-decoration: none;
  margin: 5px;
  display: block;
}

.contacts__instagram {
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 15px;
  background-color: rgb(153, 153, 153);
  transition: all 0.2s ease-in-out;
  display: block;
  margin: auto;

  &:hover {
    background-color: rgb(238, 42, 123);
    background-image: linear-gradient(115deg, rgb(249, 206, 52), rgb(238, 42, 123), rgb(98, 40, 215));
  }
}

.contacts__instagram__svg {
  max-width: 100%;
  height: auto;
}

.contacts___wrap {
  margin-bottom: 24px;
}

.slider {
  margin-bottom: 50px;
  background-color: rgb(62, 62, 62);
  padding-top: 30px;
}

.slide__title {
  padding: 20px;
  background-color: rgb(50, 45, 40, 0.6);
  position: absolute;
  z-index: 100;
  top: 10%;
  left: 20%;
  color: white;
  font-size: 38px;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

.img {
  height: 670px;
  margin: 0 auto;
}

.last {
  left: 35%;
}

.btn {
  cursor: pointer;
  border: none;
  color: white;
  width: max-content;
  font-size: 20px;
  margin: 0;
  text-decoration: none;
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  transition: all 0.2s ease-in-out;
}

.btn__icon {
  position: relative;
  right: 0;
  transition: all 0.2s ease-in-out;
}

.slide__title:hover {
  .btn {
    border-bottom: 2px solid rgba(255, 255, 255, 0);
  }

  .btn__icon {
    right: -10px;
  }
}

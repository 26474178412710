.catalog {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;

  @media screen and (max-width: 480px){
    grid-template-columns: 1fr;
  }
}

.cardBox {
  position: relative;
  display: flex;
  color: black;
  height: 180px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #b48c5498;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: -5px;
    width: 5px;
    height: 100%;
    background-color: rgba(255, 200, 0, 0.3);
    z-index: -1;
  }
}

.cardList {
  width: 100%;
  padding: 15px 20px;
}

.cardListBig{
  @media screen and (max-width: 480px){
    height: 250px;
  }
}

.imgCatalog {
  height: 100% ;
}

.imgCatalogBig{
  @media screen and (max-width: 480px){
    width: 160px;
    height: 180px;
    margin: auto;
      }

}

.imgCatalogS{
  @media screen and (max-width: 480px){
   display: flex;
      }
}

.serias {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 30px;
  @media screen and (max-width: 480px){
    grid-template-columns: repeat(1, 1fr);
  }
}

.more {
  position: relative;
  text-decoration: none;
  color: black;

  &:hover {
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 2px;
    }
  }

  &::after {
    content: '>';
    position: absolute;
    left: -15px;
    bottom: 0;
    z-index: -1;
    color: black;
  }
}

.listTitle {
  @media screen and (max-width: 480px){
    font-size:medium;
  }
}

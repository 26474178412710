// .carSlider{
//     height: 933.781px;
// }

.btnPrev {
    position: absolute;
    top: 865px;
    right: 1250px;
    background-image: url(../../Home/img/Vlevo.png);
    background-repeat: no-repeat ;
    background-size: contain ;
    width: 50px ;
    height: 50px ;
    padding-right: 130px;
    background-color: white;
    background-position: center;
    border: none;
    z-index: 99;
    transition: .5s;
  }
  .btnPrev:hover {
    background-image: url(../../Home/img/Vlevowhite.png);
    background-color: #8c8c8c;
    stroke: white;
  }

  .btnNext {
    position: absolute;
    right: 1090px;
    top: 865px;
    background-image: url(../../Home/img/vpravo.png);
    background-repeat: no-repeat ;
    background-size: contain ;
    width: 50px ;
    height: 50px ;
    padding-right: 130px;
    background-color: white;
    background-position: center;
    border: none;
    z-index: 99;
    transition: .5s;
  
  }
  .btnNext:hover {
    background-image: url(../../Home/img/vpravowhite.png);
    background-color: #8c8c8c;
    stroke: white;
  }
.project {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}
.slide {
  display: flex;
  width: 100%;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  @media screen and (min-width: 320px) and (max-width: 480px) { /* Адаптація для мобільних пристроїв */
    display: flex;
    justify-content: space-between;
  }
}

//   .leftBar {
//    width: 25%;
//   }

// .rightBar {
//     width: 75%; /* або будь-який інший розмір, який вам підходить */
//     margin: 0 auto; /* це центрує ваш контейнер на сторінці */

//   }
.carusel {
  width: 75%;
  margin: 0 auto;
  @media screen and (max-width:480px) {
    width: 100%;
  }
}
.bigPhoto {
  width: 25%;
  height: 250px;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 320px) and (max-width: 480px) { /* Адаптація для мобільних пристроїв */
    display: none;
  }
}
.imgBigPhoto {
  // width: 100%;
  height: 100%;
  display: flex;

  
}
.imgBigPhoto.hidden {
  opacity: 0;
  animation: fadeInOut 1s ease-in-out alternate forwards;  /* приховати зображення, якщо встановлено клас hidden */
}

/* Анімація fadeInOut */
@keyframes fadeInOut {
  0% {
    opacity: 1; /* початкова прозорість - 0 (повністю невидиме) */
  }
  50% {
    opacity: 0; /* проміжна прозорість - 1 (повністю видиме) */
  }
  100% {
    opacity: 1; /* кінцева прозорість - 0 (повністю невидиме) */
  }
}



.titleCar{
  text-align: center;
  @media screen and (max-width:480px) {
    font-size: 40px;
    margin-bottom: 0;
  }
  
}
.peregorodkibox {
  display: flex;
  margin-top: 25px;
  @media (min-width: 481px) and (max-width: 768px){
    width: 90%;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 90%;
  }
}
.titleper {
  font-family: Montserrat, sans-serif;
  margin-bottom: 55px;
  color: #454545;
  padding: 15px;
  font-size: 36px;
  text-align: left;
  display: inline-block;
  border: 2px #292929 solid;
  letter-spacing: 1.5px;
  @media screen and (max-width: 480px) {
    margin-left: 20px;
  }
}
.perslider {
  width: 1325px;
  @media screen and (max-width: 480px) {
    max-width: 450px;
  }
}
.infolistper {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  // padding-right: 165px;
  list-style-type: none;
  @media (min-width: 481px) and (max-width: 768px) {
    padding-left: 25px;
  padding-right: 0;
  }
  @media screen and (max-width: 480px) {
    padding-right: 0;
    width: 90%;
    margin-left: 10px;
  }
}
.infoper {
  font-family: Montserrat, sans-serif;
  color: #828282;
  line-height: 40px;
  font-size: 25px;
  list-style-type: none;
  position: relative;
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    line-height: 20px;
  font-size: 15px;
  }
}
.infoper::before {
  position: absolute;
  top: 10px;
  left: -21px;
  display: flex;
  content: '';
  background: url(./img/rig.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 17px;
}
.titletypeper {
  font-family: Montserrat, sans-serif;
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 3px;
  color: #333;
  margin-bottom: 40px;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: 481px) and (max-width: 768px) {
    font-size: 25px;
  }
  @media screen and (max-width: 480px) {

    font-size: 25px;
  }
}
.typeinfo {
  display: flex;
  justify-content: space-around;
  list-style-type: none;

  p{
    width: 500px;
  }
  @media (min-width: 481px) and (max-width: 768px){
    flex-direction: column;
    p{
      width: 80%;
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding: 0;

  }
}
.gridBox{
  p{
    text-align: center;
  }
  @media (min-width: 481px) and (max-width: 768px){
    width: 90%;

    }
  @media screen and (max-width: 480px) {
  width: 90%;
    p{
      text-align: center;
    }
  }
}
.perslider{
  @media (min-width: 481px) and (max-width: 768px){
    max-width: 370px;
    margin: 0 auto;
  }
  @media screen and (max-width: 480px) {
    max-width: 370px;
    margin: 0 auto;
  }
}
.typeper{
  @media screen and (max-width: 480px) {
    max-width: 400px;
    margin: 0 auto;
    li{
      list-style-type: none;
    }
  }
}
.imgType{
  @media screen and (max-width: 480px) {
    max-width: 370px;
  }
}

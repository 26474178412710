.peregorodkibox {
  display: flex;
  margin-top: 25px;
  @media (min-width: 481px) and (max-width: 768px) {
    width: 90%;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 90%;
  }
}
.titleper {
  font-family: Montserrat, sans-serif;
  margin-bottom: 55px;
  color: #454545;
  padding: 15px;
  font-size: 36px;
  text-align: left;
  display: inline-block;
  border: 2px #292929 solid;
  letter-spacing: 1.5px;
  @media screen and (max-width: 480px) {
    margin-left: 20px;
  }
}
.perslider {
  width: 1325px;
}

.infolistper {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  padding-right: 165px;
  list-style-type: none;
  @media (min-width: 481px) and (max-width: 768px) {
    padding-left: 25px;
    padding-right: 0;
  }
  @media screen and (max-width: 480px) {
    padding-right: 0;
    width: 90%;
    margin-left: 10px;
  }
}
.infoper {
  font-family: Montserrat, sans-serif;
  color: #828282;
  line-height: 40px;
  font-size: 25px;
  list-style-type: none;
  position: relative;
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    line-height: 20px;
    font-size: 15px;
  }
}
.infoper::before {
  position: absolute;
  top: 10px;
  left: -21px;
  display: flex;
  content: '';
  background: url(./img/rig.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 25px;
  height: 17px;
}

.perslider {
  @media (min-width: 481px) and (max-width: 768px) {
    max-width: 370px;
    margin: 0 auto;
  }
  @media screen and (max-width: 480px) {
    max-width: 370px;
    // margin: 0 auto;
    margin-left: 12px;
  }
}

.colorBlock {
  padding-top: 35px;
  align-items: center;
  text-align: center;
  h1 {
    padding-bottom: 20px;
  }
  h4 {
    padding: 0 50px;
  }
}
.alColor {
  display: flex;
  justify-content: space-around;
  padding: 0 50px;
  p {
    font-size: 25px;
    font-weight: 550;
  }
  @media screen and (max-width: 480px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    p {
      font-size: 15px;
      font-weight: 550;
    }
  }
}
.sizeColor {
  width: 230px;
  @media screen and (max-width: 480px) {
    width: 150px;

  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 70px;
  justify-content: center;
  margin-bottom: 50px;
}

.item {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100px;
  cursor: pointer;

  &:hover {
    .img {
      transform: scale(1.1);
    }

    .btn {
      background-color: rgb(209, 156, 75);
    }
  }
}

.img {
  transition: all 0.2s ease-in-out;
}

.btn {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(185, 133, 58);
  padding: 10px 15px;
  font-weight: 500;
  font-size: 18px;
  border: none;
  transition: all 0.2s ease-in-out;
}

.header {
  height: 60px;
  padding: 0 !important;
  background-color: rgb(185, 133, 58);
}

.header__placeholder {
  height: 60px;
}

.header__container {
  height: 60px;
  width: 100vw;
  max-width: none !important;
}

.header__content {
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out !important;
  background-color: rgb(185, 133, 58);
}

@media screen and (max-width: 890px) {
  .header__content {
    height: max-content;
  }

  .nav {
    height: max-content;
    display: block !important;
  }

  .nav__link {
    padding: 15px !important;
  }
}

.logo {
  height: 60px;
  width: auto;
  padding: 0 40px;
  background-color: black;
}

.nav {
  height: 100%;
  cursor: pointer;
  display: flex;
}

.header .nav .nav__link {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px solid transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 0 20px;
}

.nav .nav__link:hover {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
  background-color: rgb(209, 156, 75);
}

.navbar-toggler {
  width: 45px !important;
  padding: 5px !important;
  border: 2px solid rgb(0, 0, 0, 0.65) !important;
}

.navbar-toggler:hover {
  background-color: rgb(209, 156, 75);
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

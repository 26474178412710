@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Montserrat, 'Helvetica Neue', sans-serif;
}

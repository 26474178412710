.footer {
  background-color: rgb(185, 133, 58);
  color: black;
  display: flex;
  padding: 20px;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100%;

  @media screen and (max-width: 600px) {
    padding: 12px;
  }

  @media screen and (max-width: 400px) {
    padding: 20px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.contacts__label {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.contacts__phone,
.contacts__phone_2,
.contacts__mail {
  margin: 5px 0;
  color: black;
  text-decoration: none;
  display: block;
}

.contacts__address {
  color: black;
  text-decoration: none;
  margin-top: 10px;
  display: block;
}

.contacts__instagram {
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 15px;
  background-color: rgb(209, 156, 75);
  transition: all 0.2s ease-in-out;
  display: block;

  &:hover {
    background-color: rgb(238, 42, 123);
    background-image: linear-gradient(115deg, rgb(249, 206, 52), rgb(238, 42, 123), rgb(98, 40, 215));
  }
}

.contacts__instagram__svg {
  max-width: 100%;
  height: auto;
}

.contacts___socials_wrap,
.contacts___address_wrap {
  @media screen and (max-width: 890px) {
    display: none;
  }
}

.contacts__phone_2 {
  @media screen and (max-width: 400px) {
    display: none;
  }
}

.contacts___phone_wrap,
.contacts___mail_wrap {
  @media screen and (max-width: 400px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
